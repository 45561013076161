var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "root" }, [
    _c("div", { staticClass: "body" }, [
      !_vm.notifications ? _c("div", [_vm._v("読み込み中")]) : _vm._e(),
      _c("div", [
        _c(
          "div",
          { staticClass: "list" },
          [
            _vm._l(_vm.notifications, function (notification) {
              return _c(
                "div",
                { key: notification.notificationId, staticClass: "list-item" },
                [
                  _c("img", {
                    staticClass: "icon",
                    attrs: { src: notification.iconUrl },
                  }),
                  _c(
                    "div",
                    { class: `content ${notification.isRead ? "isRead" : ""}` },
                    [
                      _c("div", { staticClass: "message" }, [
                        _vm._v(_vm._s(notification.message)),
                      ]),
                      _c("div", { staticClass: "timestamp" }, [
                        _vm._v(_vm._s(_vm.dateFormat(notification.createdAt))),
                      ]),
                    ]
                  ),
                ]
              )
            }),
            _c("InfiniteLoading", { on: { infinite: _vm.handleInfinite } }, [
              _c("span", { attrs: { slot: "no-more" }, slot: "no-more" }),
              _c(
                "span",
                { attrs: { slot: "no-results" }, slot: "no-results" },
                [
                  _c("div", { staticClass: "empty" }, [
                    _vm._v("お知らせはありません"),
                  ]),
                ]
              ),
            ]),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }